<template>
	<v-sheet flat>
		<render-content id="analytics"> </render-content>
		<loading v-if="loading"></loading>
		<div v-else>
			<analysis-sidebar
				@input="(key, value) => ($data[key] = value)"
				:seriesAggregation="seriesAggregation"
				:series="series"
				:selectedQuestions="selectedQuestions"
				:chartType="chartType"
				:chartOptions="chartOptions"
				:visualisationType="visualisationType"
				:tableRows="tableRows"
				:chartData="chartData"
				:tableColumns="tableColumns"
			></analysis-sidebar>
			<v-row>
				<v-col cols="12">
					<Chart
						:loading="loading"
						id="chart"
						v-if="visualisationType == 'chart'"
						:type="chartType"
						v-bind="chartData"
						:options="chartOptions"
					></Chart>
					<v-data-table
						v-else
						:items="tableRows"
						:headers="tableColumns"
					></v-data-table>
				</v-col>
			</v-row>
		</div>
	</v-sheet>
</template>

<style lang="less"></style>

<script>
import Loading from "@c/ui/Loading";
import AnalysisSidebar from "@c/results/analysis/Sidebar";
import Chart from "@c/results/analysis/Chart";
import RenderContent from "@c/ui/RenderContent";

export default {
	name: "Analysis",
	data: () => {
		return {
			loading: false,
			seriesAggregation: "average",
			visualisationType: "chart",
			chartType: "BarChart",
			selectedQuestions: [],
			vOptions: {
				scales: {
					xAxes: [
						{
							type: "category",
						},
					],
					yAxes: [
						{
							display: true,
							scaleLabel: {
								display: false,
								labelString: "",
							},
							ticks: {
								max: 100,
								beginAtZero: true, // minimum value will be 0.
							},
						},
					],
				},
			},
			hOptions: {
				scales: {
					yAxes: [
						{
							type: "category",
						},
					],
					xAxes: [
						{
							display: true,
							scaleLabel: {
								display: false,
								labelString: "",
							},
							ticks: {
								max: 100,
								beginAtZero: true, // minimum value will be 0.
							},
						},
					],
				},
			},
			defaultColors: ["#005EB8", "#006747", "grey"],
			series: [],
		};
	},
	components: {
		Chart,
		Loading,
		AnalysisSidebar,
		RenderContent,
	},
	computed: {
		order() {
			return this.$store.getters.order;
		},
		responses() {
			return this.$store.state.sectionResponses.data;
		},
		organisation() {
			return this.$store.state.organisation.data;
		},
		sections() {
			return this.$store.state.sections.data;
		},
		themes() {
			return this.$store.getters["themes/options"];
		},
		results() {
			const self = this;
			return this.order
				.filter((item) => self.responses[item.response])
				.flatMap((item) => {
					let response = self.responses[item.response];
					return this.mwsurveyutils.processResponse(response);
				})
				.filter((exists) => exists);
		},
		dataPoints() {
			const self = this;
			return self.results.filter((result) =>
				self.selectedQuestions.includes(result.question)
			);
		},
		labels() {
			let labels = {};
			const self = this;
			Object.keys(self.sections).map((s) => {
				labels[s] = self.sections[s].name;
			});
			self.themes.map((s) => {
				labels[s.id] = s.name;
			});
			self.dataPoints.forEach((q) => {
				let questionText = self.$store.getters[
					"questions/getQuestionTexts"
				](q.question);
				labels[q.question] = questionText.string;
			});
			return labels;
		},
		chartOptions(){
			return	this.chartType == 'HBarChart' ? this.hOptions : this.vOptions
		},
		seriesData() {
			const self = this;
			var scores = {};
			if (self.seriesAggregation == "average") {
				scores.average = self.dataPoints;
			} else {
				self.dataPoints.forEach((dp) => {
					var key;
					if (self.seriesAggregation == "section") {
						key = dp.section;
					}
					if (self.seriesAggregation == "question") {
						key = dp.question;
					}
					if (self.seriesAggregation == "theme") {
						let section = self.sections[dp.section];
						key = section.theme;
					}
					if (!scores[key]) {
						scores[key] = [];
					}
					scores[key].push(dp);
				});
			}

			var average = {};
			for (var key in scores) {
				let values = scores[key]
					.filter((a) => a.answer)
					.map((a) => a.score);
				average[key] = self.mwutils.average(values).toFixed(2);
			}
			return average;
		},
		chartData() {
			const self = this;

			var datasets = {
				data: [],
				labels: [],
			};

			if (!self.selectedQuestions.length) {
				return datasets;
			}

			var output = self.seriesData;
			datasets.labels = Object.keys(output);

			const series = self.series;

			series.forEach((s, i) => {
				datasets.data.push({
					backgroundColor: s.color || self.defaultColors[i],
					label: s.name,
					id: s.id,
					data: Object.values(output) || [],
				});
			});
			datasets.labels = datasets.labels.map((l) => self.labels[l] || l);

			return datasets;
		},
		tableColumns() {
			var columns = [{ text: "Item", value: "item" }];
			columns.push({
				text: this.organisation.name,
				value: this.organisation.id,
			});
			return columns;
		},
		tableRows() {
			var self = this;
			var data = [];
			self.chartData.labels.forEach((label, i) => {
				var item = { item: label };
				self.chartData.data.forEach((d) => {
					let value = d.data[i];
					if (value) {
						value = new Number(value);
						value = value.toFixed(2) + "%";
					}
					item[d.id] = value || "-";
				});
				data.push(item);
			});
			return data;
		},
	},
	created() {
		this.$store.dispatch("sections/openDBChannel")
		this.series.push({
			name: this.organisation.name,
			id: this.organisation.id,
			color: this.defaultColors[0],
		});
	},
	methods: {},
	watch: {},
	mounted() {},
};
</script>
